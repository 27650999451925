<template>
  <v-select
    :value="selectedServices"
    :items="options"
    :loading="loading"
    :menu-props="menuProps"
    ref="input"
    label="Services"
    multiple
    outlined
    clearable
    hide-details
    @click:clear="$emit('change', null)"
    @change="onChange($event)"
  >
    <template v-if="services && services.length" v-slot:append>
      <v-badge :content="services.length" primary inline />
    </template>

    <template #prepend-item>
      <div class="clinical-services-multiselect__search">
        <v-list-item>
          <v-list-item-content class="pt-2 pb-0">
            <v-text-field
              placeholder="Search service"
              prepend-inner-icon="mdi-magnify"
              dense
              outlined
              clearable
              hide-details
              @input="onSearch"
            />
          </v-list-item-content>
        </v-list-item>
        <v-divider class="mt-2"></v-divider>
      </div>
    </template>

    <template v-slot:selection="data">
      <v-chip
        v-if="data.index < 2"
        close
        small
        label
        @click:close="onRemove(data.item)"
      >
        {{ data.item.text }}
      </v-chip>
      <span v-if="data.index === 2">...</span>
    </template>
  </v-select>
</template>

<script>
import { debounce } from "lodash";
import { getClinicalServices } from "@/services/clinicalServices";

const menuProps = {
  bottom: true,
  offsetY: true,
  left: true,
};

export default {
  props: {
    services: {
      type: Array,
      default: () => null,
    },
    auditId: {
      type: String,
      default: () => null
    }
  },

  data() {
    return {
      selectedServices: null,
      loading: false,
      clinicalServices: [],
      menuProps,
      itemsCount: 0,
    };
  },

  computed: {
    options() {
      if (!this.clinicalServices.length) return [];
      return this.clinicalServices.map((service) => ({
        value: service._id,
        text: service.name,
      }));
    },
  },

  async mounted() {
    await this.initialize();
  },

  methods: {
    async initialize() {
      this.selectedServices = this.services || null;
      await this.fetch();
      if (this.returnObject && this.value) {
        if (!this.options.length) return this.$emit("change", null);
        const optionsMap = this.options.reduce((acc, option) => {
          acc[option.value] = option;
          return acc;
        }, {});
        this.$emit(
          "change",
          this.value.map(({ value }) => optionsMap[value])
        );
      }
    },
    async fetch(query) {
      try {
        this.loading = true;
        const data = await getClinicalServices(query);
        this.clinicalServices = data;
        this.clinicalServices = data.filter((service) => {
          if (service?.templateId) {
            if (this.auditId) return service.templateId === this?.auditId;
            return service;
          }
          if (!service?.templateId) return service;
        });
      } catch (e) {
        this.$notify({
          type: "error",
          text: e?.message || JSON.stringify(e),
        });
      } finally {
        this.loading = false;
      }
    },
    onChange(value) {
      this.selectedServices = value?.length ? value : null;
      this.$emit("change", this.selectedServices);
    },
    onSearch: debounce(function (search) {
      const query = {};
      if (search?.length) {
        const regex = { $regex: search, $options: "i" };
        query.filter = {
          name: regex,
        };
      }
      this.fetch(query);
    }, 800),
    onRemove({ value }) {
      const services = this.selectedServices.filter(
        (service) => value !== service
      );
      this.selectedServices = services?.length ? services : null;
      this.$emit("change", services);
    },
    reset(){
      this.$refs.input.reset();
    },
  },
};
</script>
