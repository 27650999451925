<template>
  <v-dialog
    :value="dialog"
    width="616"
    style="z-index: 20001"
    @click:outside="toggleDialog"
  >
    <v-card class="create-batch-modal pa-5">
      <v-card-title class="text-h5 lighten-2 pa-0"> Create Batch </v-card-title>
      <v-card-text class="pa-0"> Choose batch type </v-card-text>

      <v-form class="pt-5">
        <v-text-field
          v-model="name"
          :error-messages="nameErrors"
          type="text"
          class="mb-2"
          clearable
          outlined
          label="Batch name"
          @input="$v.name.$touch()"
          @blur="$v.name.$touch()"
        />
        <v-select
          v-model="service"
          :items="serviceOptions"
          :value="clinicalServices"
          :error-messages="typeErrors"
          :menu-props="{ bottom: true, offsetY: true }"
          label="Service"
          class="mb-2"
          clearable
          outlined
          @change="$v.service.$touch()"
          @blur="$v.service.$touch()"
        />
        <v-select
          v-if="!batchType"
          v-model="type"
          :items="batchItems"
          :error-messages="typeErrors"
          :menu-props="{ bottom: true, offsetY: true }"
          label="Batch type"
          clearable
          outlined
          @change="$v.type.$touch()"
          @blur="$v.type.$touch()"
        />
        <AuditTemplateSelect
          v-if="isSpecialOrGeneralAuditSelected"
          :value="templateId"
          :item-value="'_id'"
          :hide-details="false"
          :error-messages="templateIdErrores"
          @change="onTemplateChange"
          @blur="$v.templateId.$touch()"
        />
      </v-form>

      <v-card-actions class="pa-0 pt-8 d-flex justify-end">
        <v-btn
          color="primary"
          text
          normal
          :disabled="loading"
          @click="toggleDialog"
        >
          Cancel
        </v-btn>
        <v-btn
          :disabled="$v.$invalid"
          :loading="loading"
          color="primary"
          rounded
          normal
          variant="elevated"
          @click="onSubmit"
        >
          Create
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "pinia";
import { validationMixin } from "vuelidate";
import { requiredIf, required } from "vuelidate/lib/validators";
import { BatchTypes, PagesBreadCrumbTitles } from "../../misc/constants";
import { useUserStore } from "../../stores/user";
import { checkBatchName, generateBatch } from "../../services/batch";
import AuditTemplateSelect from "../controls/AuditTemplateSelect.vue";

export default {
  name: "CreateBatchModal",

  components: {
    AuditTemplateSelect,
  },

  mixins: [validationMixin],

  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
    batchType: {
      type: String,
      default: "",
    },
    batchTypes: {
      type: Array,
      default: null,
    },
    clinicalServices: {
      type: Array,
      default: () => [],
    },
    isTemplateAudit: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      name: null,
      service: null,
      type: this.batchType || null,
      loading: false,
      auditTemplates: null,
      templateId: null,
    };
  },

  validations: {
    type: { required },
    name: { required },
    service: { required },
    templateId: {
      required: requiredIf(function () {
        return this.type === BatchTypes.specialAudit;
      }),
    },
  },

  computed: {
    ...mapState(useUserStore, ["user", "isAdmin"]),
    serviceOptions() {
      if (!this.clinicalServices || !this.clinicalServices.length) return [];
      return this.clinicalServices.map((service) => ({
        value: service,
        text: service.name,
      }));
    },
    nameErrors() {
      const errors = [];
      if (!this.$v.name.$dirty) return errors;
      !this.$v.name.required && errors.push("Name is required");
      return errors;
    },
    typeErrors() {
      const errors = [];
      if (!this.$v.type.$dirty) return errors;
      !this.$v.type.required && errors.push("Type is required");
      return errors;
    },
    templateIdErrores() {
      const errors = [];
      if (!this.$v.templateId.$dirty) return errors;
      !this.$v.templateId.required &&
        errors.push("Template is required for Special Audit");
      return errors;
    },
    batchItems() {
      if (this.batchTypes) return this.batchTypes;
      return [BatchTypes.docReview, BatchTypes.specialAudit];
    },
    isSpecialOrGeneralAuditSelected() {
      return (
        this.type === BatchTypes.specialAudit ||
        this.type === BatchTypes.general
      );
    },
  },

  methods: {
    toggleDialog() {
      this.$emit("toggle", !this.dialog);
      this.resetData();
    },
    async onSubmit() {
      this.loading = true;
      if (this.type === BatchTypes.groupReview) {
        await this.generateGroupReviewBatch();
      } else {
        const isUnavailableName = await checkBatchName(this.name);
        if (isUnavailableName) {
          this.loading = false;
          return this.$notify({
            type: "error",
            title: "Generate batch",
            text: "Batch with this name already exists. Please choose another Batch name.",
          });
        }
        this.$router.push({
          path: "/batches/new",
          query: {
            name: this.name,
            service: this.service._id,
            serviceName: this.service.name,
            type: this.type,
            ...([BatchTypes.specialAudit, BatchTypes.general].includes(
              this.type
            )
              ? { templateId: this.templateId }
              : {}),
            prev:
              this.$route?.meta?.breadcrumb?.title ||
              PagesBreadCrumbTitles.batches,
          },
        });
      }
      this.loading = false;
      this.toggleDialog();
    },
    async generateGroupReviewBatch() {
      try {
        const body = {
          type: this.type,
          name: this.name,
          auditorId: this.user._id,
        };
        const batch = await generateBatch(body);
        this.$router.push({
          path: "/batches/new",
          query: {
            name: this.name,
            type: this.type,
            batchId: batch._id,
          },
        });
        this.toggleDialog();
      } catch ({ response }) {
        this.$notify({
          type: "error",
          title: "Generate batch",
          text: response?.data?.message || JSON.stringify(response),
        });
      }
    },
    resetData() {
      this.name = null;
      this.type = this.batchType || null;
      this.templateId = null;
    },
    onTemplateChange(templateId, auditType) {
      this.templateId = templateId;
      this.type = auditType || this.batchType;
      this.$v.templateId.$touch();
    },
  },
};
</script>
